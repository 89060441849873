import React, { FC, useEffect, useRef } from "react";
import BreadcrumbItem from "./breadcrumbs-item";

export type BreadcrumbsProps = {
  breadcrumbs?: { path: string; name: string }[];
  homepage: string;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ homepage, breadcrumbs = [] }: BreadcrumbsProps): JSX.Element | null => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      (scrollRef.current as any).scrollLeft = (scrollRef.current as any).offsetWidth;
    }
  }, [scrollRef]);
  if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
    return (
      <div id="product-detail-new-breadcrumbs" data-drroot="breadcrumb">
        <div className="shadow"></div>
        <div className="breadcrumb-wrapper">
          <ul className="breadcrumb-list" ref={scrollRef as any}>
            <BreadcrumbItem text={homepage} />
            {breadcrumbs.map((breadcrumb: { path: string; name: string }) => (
              <BreadcrumbItem
                key={`productDetailBreadcrumb#${breadcrumb.path}`}
                data-testid={`productDetailBreadcrumb#${breadcrumb.path}`}
                link={breadcrumb.path.startsWith("/") ? breadcrumb.path : `/${breadcrumb.path}`}
                text={breadcrumb.name}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return <div data-drroot="breadcrumb"></div>;
};

export default Breadcrumbs;
