/* istanbul ignore file */
import ReactDOM from "react-dom";
import * as React from "react";
import { FRAGMENT_NAME } from "../constant";
import Breadcrumbs from "./components/breadcrumbs/breadcrumbs";
import "./styles/style.scss";

if (window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <Breadcrumbs {...window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]} />,
    document.getElementById(FRAGMENT_NAME)
  );
}
